<template>
	<div class="main">
		<el-form ref="form" :model="form" class="form" label-position="left">
			<div class="registration-information">
				<div class="text">报名信息</div>
				<div class="form">
					<el-form-item v-for="(i,n) in formArr" v-if='n<9' :key="i.label" :label="i.label" :prop="i.value" label-width="140rem">
						{{ form[i.value] }}
					</el-form-item>
				</div>
			</div>

			<div class="registration-information" style="margin: 24rem 0">
        <div class="text">缴费信息
          <span style="background: #D6FDDB;color: #1ECE41;margin-left: 48rem;font-size: 14rem;padding: 6px 8rem;border-radius: 4rem;" v-if="form.is_training==='是'">该学生已参加预报名</span>
        </div>
				<div class="form">
          <el-form-item v-for="(i,n) in formArr" v-if='i.label === "缴费时间" ? form.payment_type === "线下已缴" : n>=9&&n<=12&&!i.show' :key="i.label" :class="i.label==='是否缴纳高一学年生活费'?'form-item':''" :label="i.label === '高一学年实缴金额' && form.payment_type === '线上待缴' ? '高一学年应缴金额' : i.label" :prop="i.value" label-width="140rem">
						{{ form[i.value] }}
					</el-form-item>
					<div style="grid-column: 1 / -1">
						<el-form-item v-for="(i,n) in formArr" v-if='n===13' :key="i.label" :label="i.label" :prop="i.value" label-position="top" label-width="140rem">
							<el-button v-for="i in form[i.value]" :key="i">{{ i }}</el-button>
						</el-form-item>
					</div>
					<div style="grid-column: 1 / -1">
						<el-form-item v-for="(i,n) in formArr" v-if='(n>=14&&n<=16&&form[i.value]) ?  i.label === "缴费凭证" ? form.payment_type === "线下已缴" : true : false' :key="i.label" :class="i.label==='【特殊减免】优惠原因'?'form-item':''" :label="i.label" :prop="i.value" label-width="140rem">
							<div v-if="!Array.isArray(form[i.value])">
								{{ form[i.value] }}
							</div>
							<div v-else>
								<el-image v-for="fit in form[i.value]" v-if="form[i.value]&&form[i.value].length" :key="fit" :preview-src-list="form[i.value]" :src="fit" style="width: 100rem; height: 100rem;margin-right: 12rem"></el-image>
								<el-button v-if="form[i.value]&&!form[i.value].length">无</el-button>
							</div>
						</el-form-item>
					</div>
				</div>
			</div>

			<div class="registration-information" style="margin: 24rem 0">
				<div class="text">
					续费信息
				</div>
				<div class="form">
					<el-form-item v-for="(i,n) in formArr" v-if='n>16' :key="i.label" :label="i.label" :prop="i.value" label-width="140rem">
						{{ form[i.value] }}
					</el-form-item>
				</div>
			</div>

			<div v-if="form.remarks" class="registration-information" style="margin: 24rem 0;">
				<div class="text">备注</div>
				<div class="form" style="display: flex !important;">
					{{ form.remarks || '-' }}
				</div>
			</div>

			<div class="registration-information" style="margin: 24rem 0;">
				<div class="text">操作记录</div>
				<div class="form" style="grid-template-columns: 1fr 2fr;">
					<el-form-item v-for="(i,n) of formHistory" :key="i.label" :label="n" :prop="i.value" label-width="140rem">
						<span :style="{color: form['history'][i] === '待审批' ? '#F09D12' : form['history'][i] === '已通过' ? '#23C343' : form['history'][i] === '已驳回' ? '#F53F3F' : ''}">
							<span v-if="n==='驳回原因'" style="background: #F6F6F6;padding: 0rem 12rem;box-sizing: border-box;color: #676767;display: inline-block">
								{{ form['history'][i] }}
							</span>
							<span v-else>{{ form['history'][i] }}</span>
						</span>
					</el-form-item>
				</div>
			</div>
		</el-form>
		<div class="button">
			<el-button type="primary" @click="$router.back()">返回</el-button>
		</div>
	</div>
</template>
<script>
import uploadImg from '@/components/common/upload.vue'
import LUpload from "@/views/student-recruitment/client-server/supplement-to-the-enrollment-of-new-students/l-upload.vue";

export default {
	_config: {"route": {"path": "look", "meta": {"title": "查看"}}},
	components: {LUpload, uploadImg},
	data() {
		return {
			formArr: [
				{
					label: '报名校区',
					value: 'school_name',
				},
				{
					label: '学生类型',
					value: 'student_type',
				},
				{
					label: '入学年份',
					value: 'grade_year',
				},
				{
					label: '所属业务线',
					value: 'business_line',
				},
				{
					label: '入学年级',
					value: 'grade_name',
				},
				{
					label: '住宿寝室',
					value: 'standard_name',
				},
				{
					label: '学生姓名',
					value: 'student_name',
					placeholder: '请输入学生姓名'
				},
				{
					label: '学生身份证号',
					value: 'student_idcard',
				},
				{
					label: '',
					value: '',
				},
				{
					label: '缴费方式',
					value: 'payment_type',
				},
				{
					label: '是否缴纳高一学年生活费',
					value: 'living_pay',
				},
				{
					label: '高一学年实缴金额',
					value: 'payment_amount',
					placeholder: '请输入高一学年实缴金额'
				},
				{
					label: '缴费时间',
					value: 'pay_time',
					placeholder: '请选择缴费时间'
				},
				{
					label: '所参与优惠政策',
					value: 'policy',
				},
				{
					label: '其他优惠原因',
					value: 'other_reasons',
				},
				{
					label: '【特殊减免】优惠原因',
					value: 'reduction_reasons',
				},
				{
					label: '缴费凭证',
					value: 'payment_voucher',
				},
				{
          label: '高二学年应缴金额',
					value: 'two_payment_amount',
				},
				{
          label: '高三学年应缴金额',
					value: 'three_payment_amount',
				},
			],
			form: {
				school_id: '',
				grade_id: '',
				grade_year: '',
				student_type: '',
				business_line: '',
				goods_standard_id: '',
				student_name: '',
				student_idcard: '',
				contract_status: '',
				payment_type: '',
				living_pay: '',
				payment_amount: '',
				pay_time: '',
				policy: [],
				other_reasons: '',
				payment_voucher: [],
				two_payment_amount: '',
				three_payment_amount: '',
				reduction_reasons: ''
			},
			formHistory: {}
		}
	},
	created() {
		this.$_register.get('api/recruit-v2/approve-fresh/detail?id=' + this.$route.query.id).then(res => {
			this.form = res.data.data
			if (this.form.history['approve_status'] === '已通过') {
				this.formHistory = {
					"录入人": "creator",
					"录入时间": "created_at",
					"审批人": "approve_user",
					"审批时间": "approve_at",
					"审批状态": "approve_status",
				}
			}
			if (this.form.history['approve_status'] === '已驳回') {
				this.formHistory = {
					"录入人": "creator",
					"录入时间": "created_at",
					"审批人": "approve_user",
					"审批时间": "approve_at",
					"审批状态": "approve_status",
					"驳回原因": "reject_reason",
				}
			}
			if (this.form.history['approve_status'] === '待审批') {
				this.formHistory = {
					"录入人": "creator",
					"录入时间": "created_at",
					"审批状态": "approve_status",
				}
			}
		})
	},
}
</script>
<style lang="scss" scoped>
.main {
	background: #F7F7F7 !important;
	box-sizing: border-box;
	min-width: 1440rem;
	padding-bottom: 24rem;
	padding-right: 24rem;

	.registration-information {
		background: #FFFFFF;
		border-radius: 8rem;
		min-height: 120rem;
		width: 100%;

		.text {
			border-bottom: 1px solid #F2F2F2;
			color: #222222;
			font-size: 20rem;
			font-weight: 500;
			height: 52rem;
			line-height: 52rem;
			text-indent: 24rem;
		}

		.form {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			padding: 24rem 48rem;
		}
	}
}

::v-deep.label-top .el-form-item__label {
	display: block;
	float: none;
}

::v-deep.label-top .el-form-item__error {
	margin-left: -120rem;
}

.textarea {
	margin-left: -120rem;
}

::v-deep.form-item .el-form-item__label {
	margin-left: -40rem;
	width: 180rem !important;
}

::v-deep.form-item .el-upload {
	display: flex;
}

::v-deep .el-upload--picture-card {
	display: none !important;
}
</style>
