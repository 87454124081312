<script>
export default {
	name: "l-upload",
	data() {
		return {
			headers: {
				authorization: 'Bearer ' + sessionStorage.getItem('TOKEN')
			},
			payment_voucher: [],
			dialogVisible: false,
			dialogVisibleUrl: '',
		}
	},
	methods: {
		addImg(e) {
			let list = e.target.files
			let count = 5 - this.payment_voucher.length
			Object.keys(list).forEach(async (item, index) => {
				if (index < count) {
					this.uploadImg({file: list[item]})
				}
			})
		},
		btnClick() {
			this.$refs.imgInput.value = ''
			this.$refs.imgInput.click()
		},
		imgUploadBefore(file) {
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
			const isLt2M = file.size / 1024 / 1024 < 20

			if (!isJPG) {
				this.$message({
					type: 'error',
					message: '上传图片只能是 jpg/png 格式!',
					duration: 2000
				})
			}
			if (!isLt2M) {
				this.$message({
					type: 'error',
					message: '上传图片大小不能超过 20MB!',
					duration: 2000
				})
			}
			return isJPG && isLt2M
		},
		imgUploadRemove(file, fileList) {
			this.payment_voucher = fileList
			this.$emit('imgUploadRemove', file.url, fileList)
		},
		imgPreview(file) {
			this.dialogVisible = true
			this.dialogVisibleUrl = file.url
		},
		uploadImg(file) {
			let boo = this.imgUploadBefore(file.file)
			if (boo) {
				const loading = this.$loading({
					lock: true,
					text: '上传中',
					customClass: 'img-upload-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				})
				this.$uploadToOSS_1(file.file, 3).then(res => {
					this.$emit('imgUpload', res)
					this.payment_voucher.push({
						name: file.file.name,
						url: res
					})
				}).finally(() => {
					loading.close()
				})
			}
		},
	}
}
</script>

<template>
	<div>
		<input v-show="false" ref="imgInput" accept="image/png, image/jpeg, image/jpg" multiple="" type="file" @change="addImg">
		<el-button class="replace-img" style="margin-bottom: 12rem;display: flex;align-items: center" @click="btnClick">点击上传</el-button>
		<el-upload :before-upload="imgUploadBefore" :file-list="payment_voucher"
							 :headers="headers" :http-request="uploadImg" :limit="5" :on-preview="imgPreview"
							 :on-remove="imgUploadRemove" accept="image/png, image/jpeg, image/jpg" action="" list-type="picture-card" name="image">
			<i class="el-icon-plus"></i>
		</el-upload>
		<p style="color: #999">（请上传5张及以内小于20M的png/jpg格式的图片）</p>
		<el-dialog :visible.sync="dialogVisible">
			<img :src="dialogVisibleUrl" alt="" width="100%">
		</el-dialog>
	</div>
</template>

<style lang="scss" scoped>

</style>
